import React, { Fragment, useState, useRef, useEffect, useContext } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import debounce from "lodash.debounce"

import Message from "elements/Message"
import Container from "../Layout/Container"
import Section from "elements/Section"
import CartLayout from "layout/CartLayout"
import ButtonGroup from "../Elements/ButtonGroup"
import UploadDropzone from "./UploadDropzone"
import UploadGuidelines from "./UploadGuidelines"
import UploadErrorNotification from "./UploadDropzone/UploadErrorNotification"

import { isIeOrEdge } from "../../services/browserCompatibility"
import { uploadDocument } from "./services/uploadDocuments"
import { AppContext } from "../../context/AppContext"
import acceptedFileTypes from "./utils/acceptedFileTypes.json"
import { uploadValidationSchema } from "./utils/uploadValidationSchema"
import { generateFormField } from "elements/Form/services/form"

let { parseFormField } = require("../../services/airtable")

const RefillUpload = ({
  pageContext,
  maxFileSize,
  maxFileCount,
  guidelines,
  icon,
  dropzoneLabel,
  cartContainer,
  documents,
  moduleDispatch,
  moduleState,
  module,
}) => {
  const fileInputRef = useRef(null)
  const [notifications, setNotifications] = useState([])
  const [filesUploaded, setFilesUploaded] = useState(documents)
  const isValid = documents.length > 0
  const { dispatch } = useContext(AppContext)

  let formFields = pageContext.formFields
  let validationSchema = uploadValidationSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const handleCloseModal = async () => {
    dispatch({
      type: "HIDE_MODAL",
    })
    dispatch({ type: "CLOSE_SLIDING_MODAL" })
    await delay(400)
    dispatch({ type: "HIDE_SLIDING_MODAL" })
    setTimeout(() => handleFileChooser(), 500)
  }

  const showUploadGuidelines = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        content: (
          <UploadGuidelines
            guidelines={guidelines}
            handleCloseModal={handleCloseModal}
          />
        ),
        heading: "Upload Guidelines",
        headerClass:
          "has-text-info has-background-info-light has-text-weight-bold has-text-centered",
        hideCloseButton: true,
        isCard: true,
        modalBackground: "green",
        hideOnMobile: true,
      },
    })
    dispatch({
      type: "SHOW_SLIDING_MODAL",
      payload: {
        content: (
          <UploadGuidelines
            guidelines={guidelines}
            handleCloseModal={handleCloseModal}
          />
        ),
        hideCloseButton: true,
        isCard: true,
        closeOnClickOutside: true,
      },
    })
  }

  useEffect(() => {
    setFilesUploaded(documents)
  }, [documents])

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  const openFileChooser = () => {
    if (filesUploaded.length < 1) {
      showUploadGuidelines()
    } else {
      if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
      else handleFileChooser()
    }
  }

  const handleUploadSubmit = (values) => {
    moduleDispatch({
      type: "SAVE_RX_DATA",
      payload: {
        ...values,
      },
    })
    moduleDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...moduleState,
        ...values,
      },
    })
    navigate(pageContext?.nextPath)
  }

  const handleAlreadyUploaded = (setNotifications) => (file) => {
    setNotifications((notificationsList) => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
  }

  const closeNotifications = () => {
    setNotifications([])
  }

  const checkInvalidFileType = ({ filesUploaded }) => {
    return filesUploaded.every((file) => {
      return !acceptedFileTypes.includes(file.type)
    })
  }

  const checkInvalidFileTypes = ({ fileUploaded }) => {
    return !acceptedFileTypes.includes(fileUploaded.type)
  }

  const handleFileRead = (event) => {
    closeNotifications()
    const tempFilesUploaded = [...event.target.files]
    const initialNotifications = []
    const hasExceededMaxFiles =
      tempFilesUploaded.length + filesUploaded.length > maxFileCount
    const hasInvalidFileType = checkInvalidFileType({
      filesUploaded: tempFilesUploaded,
    })
    switch (true) {
      case hasExceededMaxFiles:
        {
          initialNotifications.push(
            <UploadErrorNotification
              message={`Please upload only a maximum of ${maxFileCount} files.`}
            />
          )
        }
        break
      case hasInvalidFileType:
        initialNotifications.push(
          <UploadErrorNotification
            message={`You may only upload files in the following format: jpeg, jpg, png, or pdf files.`}
          />
        )
        break
      default:
        for (let i = 0; i < tempFilesUploaded.length; i++) {
          if (
            checkInvalidFileTypes({
              fileUploaded: tempFilesUploaded[i],
            })
          )
            initialNotifications.push(
              <UploadErrorNotification
                fileName={tempFilesUploaded[i].name}
                message={`has an invalid file type. You may only upload files in the following format: jpeg, jpg, png, or pdf files.`}
              />
            )
          if (
            tempFilesUploaded[i].size < maxFileSize &&
            !checkInvalidFileTypes({
              fileUploaded: tempFilesUploaded[i],
            })
          )
            uploadDocument(
              tempFilesUploaded[i],
              filesUploaded,
              setFilesUploaded,
              null,
              // (prevFilesUploaded) => {
              //   moduleDispatch({
              //     type: "SAVE_DOCUMENTS",
              //     payload: [...prevFilesUploaded],
              //   })
              //   moduleDispatch({
              //     type: "SAVE_CONTEXT_TO_SESSION",
              //     payload: {
              //       ...moduleState,
              //       documents: [...prevFilesUploaded],
              //     },
              //   })
              // },
              handleAlreadyUploaded(setNotifications),
              "rx",
              moduleState,
              dispatch,
              i,
              moduleDispatch
            )
          else if (tempFilesUploaded[i].size > maxFileSize)
            initialNotifications.push(
              <UploadErrorNotification
                fileName={tempFilesUploaded[i].name}
                message="is greater than 4MB. Please upload a file or photo less than 4MB."
              />
            )
        }
        break
    }
    setNotifications(initialNotifications)
  }

  const initialState = { ...moduleState }
  delete initialState["documents"]

  useEffect(() => {
    moduleDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Section title="Upload Prescription">
        <UploadDropzone
          label={dropzoneLabel}
          addMoreLabel="+ Add more prescription"
          icon={icon}
          fileInputRef={fileInputRef}
          handleFileChooser={openFileChooser}
          handleFileRead={handleFileRead}
          notifications={notifications}
          filesUploaded={filesUploaded}
          setFilesUploaded={setFilesUploaded}
          state={moduleState}
          dispatch={moduleDispatch}
        />
        {!isValid && (
          <p className="helper has-text-danger">
            Please upload your prescription
          </p>
        )}
      </Section>
    </Fragment>
  )
}

export default RefillUpload

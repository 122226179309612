import React, { useContext, Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import { AppContext } from "../../../context/AppContext"
import UploadFilePreview from "../../Upload/UploadFilePreview"
import Loading from "../../Elements/Loading"

import styles from "../utils/cart.module.scss"

const DocumentsCardRow = ({
  fileName,
  oldFileName,
  handleDeleteDocument,
  index,
  file,
  isFullSize,
  showOldFileName,
  showDeleteButton,
  isClickable,
}) => {
  const data = useStaticQuery(graphql`
    {
      pdfThumbnail: file(relativePath: { eq: "pdf.png" }) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const pdfThumbnail = data.pdfThumbnail.childImageSharp.fixed

  const { dispatch } = useContext(AppContext)
  let oldFileNameArray = oldFileName.split(".")
  let fileType = oldFileNameArray[oldFileNameArray.length - 1] || "file"

  let imageThumbnail =
    fileType === "pdf" ? (
      <Img fixed={pdfThumbnail} />
    ) : (
      <img
        src={file?.path}
        className={classNames("image", styles["documentRow__imgThumbnail"], {
          [styles["documentRow__imgThumbnail__isFullsize"]]: isFullSize,
          "mb-2": isFullSize,
        })}
        alt="attachment"
      />
    )

  imageThumbnail = !!file.token ? (
    imageThumbnail
  ) : (
    <Loading type="circle" height="10" width="10" />
  )

  const handleFileClick = () =>
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        content: <UploadFilePreview file={file} isPdf={fileType === "pdf"} />,
      },
    })

  return (
    <article className={classNames("media is-align-items-center")}>
      <div
        onClick={handleFileClick}
        onKeyDown={(event) => {
          if (event.key === "Enter") handleFileClick()
        }}
        role="button"
        tabIndex={0}
      >
        <figure
          className={classNames("media-left", {
            "media-content": isFullSize,
          })}
        >
          {imageThumbnail}
        </figure>
      </div>

      {(!isFullSize || fileType === "pdf") && (
        <Fragment>
          <div
            onClick={isClickable && handleFileClick}
            className="media-content"
            role="button"
            onKeyDown={(event) => {
              if (event.key === "Enter" && isClickable) handleFileClick()
            }}
            tabIndex={0}
          >
            <div className="subtitle  has-text-left">
              <span className="has-text-weight-bold">{fileName}</span>
              {showOldFileName && <p className="help">{oldFileName}</p>}
            </div>
          </div>
        </Fragment>
      )}
      {showDeleteButton && (
        <div className="media-right ml-0">
          <span
            role="button"
            tabIndex={0}
            className="delete"
            onClick={() => handleDeleteDocument(index)}
            onKeyDown={(event) => {
              if (event.key === "Enter") handleDeleteDocument(index)
            }}
            type="button"
          >
            &nbsp;
          </span>
        </div>
      )}
    </article>
  )
}

export default DocumentsCardRow

import React, { useEffect } from "react"

import DocumentsCardRow from "../../Cart/DocumentsCard/DocumentsCardRow"

const UploadPrescriptionPreview = ({
  filesUploaded,
  setFilesUploaded,
  state,
  dispatch,
  notifications,
}) => {
  const handleDeleteDocument = (index) => {
    const { documents } = state
    let documentsList = [...documents]
    documentsList.splice(index, 1)
    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...documentsList],
    })

    let tempFilesUploaded = [...filesUploaded]
    tempFilesUploaded.splice(index, 1)
    setFilesUploaded(tempFilesUploaded)
  }
  return (
    <div className="mb-1 mt-1">
      {filesUploaded?.map((file, index) => (
        <DocumentsCardRow
          fileName={file.name}
          oldFileName={file.oldname}
          index={index}
          handleDeleteDocument={handleDeleteDocument}
          file={file}
          isFullSize={false}
          showOldFileName
          showDeleteButton
          isClickable
        />
      ))}
      {notifications.length > 0 && notifications.map((item) => item)}
      {/* {notifications.length > 0 &&
        filesUploaded > 1 &&
        notifications.map((item) => item)} */}
    </div>
  )
}

export default UploadPrescriptionPreview

import React from "react"
import classNames from "classnames"

import DropzoneBody from "./UploadDropzoneBody"
import DropzoneLabel from "./UploadDropzoneLabel"

import styles from "../utils/upload.module.scss"

const UploadDropzone = ({
  label,
  addMoreLabel,
  icon,
  fileInputRef,
  handleFileChooser,
  handleFileRead,
  notifications = [],
  filesUploaded,
  setFilesUploaded,
  state,
  dispatch,
}) => {
  const handleDropzoneClick = (event) => {
    if (event.key === "Enter") handleFileChooser()
  }

  return (
    <div className={classNames("container mt-1", styles["dropzone"])}>
      <input
        type="file"
        className={styles["dropzone__input"]}
        ref={fileInputRef}
        onChange={handleFileRead}
        multiple
        accept="image/*, .pdf"
        value=""
      />

      {/* {notifications.length > 0 && notifications.map((item) => item)} */}
      <div
        className={classNames(styles["dropzone__container"])}
        onClick={filesUploaded.length > 0 ? null : handleFileChooser}
        role="button"
        tabIndex={0}
        onKeyDown={filesUploaded.length > 0 ? null : handleDropzoneClick}
      >
        <DropzoneBody
          icon={icon}
          filesUploaded={filesUploaded}
          setFilesUploaded={setFilesUploaded}
          state={state}
          dispatch={dispatch}
          notifications={notifications}
        />
        {notifications.length === 1 &&
          filesUploaded.length === 0 &&
          notifications.map((item) => item)}
        <div onClick={handleFileChooser} onKeyDown={handleDropzoneClick}>
          {filesUploaded.length !== 6 && (
            <DropzoneLabel
              label={filesUploaded.length > 0 ? addMoreLabel || label : label}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadDropzone
